<template>
  <section id="adicionar-liga">
    <div class="level">
      <div class="level-left">
        <h1 class="title is-size-4">Adicionar Ligas</h1>
      </div>
      <div class="level-right">
        <div class="bt-field">
          <b-button type="is-pigeon">Publicar</b-button>
        </div>
        <div class="bt-field">
          <b-button type="is-success">Salvar</b-button>
        </div>
      </div>
    </div>

    <ValidationObserver v-slot="{ handleSubmit }" tag="form" class="form-ligas">
      <div class="columns is-multiline">
        <div class="column is-three-quarters">
          <div class="lg-card">
            <label>Dados Gerais</label>

            <div class="columns is-multiline">
              <div class="column is-one-fifth">
                <Select
                  v-model="liga.id_influencer"
                  name="liga-tipo"
                  rules="required"
                  label="Influencer"
                >
                  <option value="a">A</option>
                </Select>
              </div>
              <div class="column is-one-fifth">
                <Select
                  v-model="liga.id_liga_tipo"
                  name="liga-tipo"
                  rules="required"
                  label="Tipo de Liga"
                >
                  <option value="a">A</option>
                </Select>
              </div>
              <div class="column is-one-fifth">
                <Select
                  v-model="liga.id_tipo_apuracao"
                  name="liga-tipo"
                  rules="required"
                  label="Tipo de Apuracao"
                >
                  <option value="a">A</option>
                </Select>
              </div>
              <div class="column is-one-fifth">
                <NumberInput
                  v-model="liga.rodada_inicio"
                  name="auth-email"
                  rules="required"
                  label="Rodada Inicio"
                />
              </div>
              <div class="column is-one-fifth">
                <NumberInput
                  v-model="liga.rodada_final"
                  name="auth-email"
                  rules="required"
                  label="Rodada Final"
                />
              </div>
              <div class="column is-full">
                <label>Descrição</label>
                <mc-wysiwyg v-model="liga.descricao" height="120"></mc-wysiwyg>
              </div>
            </div>

            <label>Ligas</label>
            <div class="columns" style="margin-top: 0.5em">
              <div class="column is-full">
                <button type="button" class="add-entry" @click="addSala">
                  <b-icon icon="plus-circle" pack="fas"></b-icon>
                  <span>Adicionar Liga</span>
                </button>

                <AddSala
                  v-for="(sala, indexSala) of liga.salas"
                  :key="`sala-${indexSala}`"
                  :index-sala="indexSala"
                  :sala="sala"
                  @change="(value) => (sala = value)"
                ></AddSala>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="lg-card">
            <label>Informação</label>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </section>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AddSala from "./components/AddSala";

export default {
  name: "AdicionarLiga",
  components: {
    ValidationObserver,
    AddSala,
  },
  data() {
    return {
      liga: {
        ativo: true,
        id_liga: 0,
        id_liga_tipo: 0,
        id_tipo_apuracao: 0,
        id_influencer: 0,
        rodada_inicio: 0,
        rodada_final: 0,
        mes: null,
        descricao: "",
        salas: [],
      },
    };
  },
  methods: {
    addSala() {
      this.liga.salas.push({});
    },
  },
};
</script>
