<template>
  <div id="add-sala">
    <b-collapse
      :aria-id="`contentIdForA11y${indexSala}`"
      class="panel"
      animation="slide"
    >
      <template slot="trigger">
        <div
          class="panel-heading"
          role="button"
          :aria-controls="`contentIdForA11y${indexSala}`"
        >
          <strong>Liga {{ indexSala + 1 }}</strong>
          <b-button
            size="is-small"
            icon-right="trash-alt"
            icon-pack="fas"
            type="is-danger"
            @click="$emit('delete')"
          ></b-button>
        </div>
      </template>
      <div class="panel-block">
        <div class="columns is-multiline">
          <div class="column">
            <Input
              v-model="innerValue.titulo"
              type="text"
              name="sala-titulo"
              label="Título"
            />
          </div>
          <div class="column">
            <NumberInput
              v-model="innerValue.valor"
              :controls="false"
              rules="required"
              name="sala-valor"
              label="Valor Inscrição"
            />
          </div>
          <div class="column centralize">
            <b-checkbox v-model="innerValue.com_capitao"
              >Com capitão?</b-checkbox
            >
          </div>
          <div class="column is-half">
            <label>Descrição</label>
            <mc-wysiwyg
              v-model="innerValue.descricao"
              height="100"
            ></mc-wysiwyg>
          </div>
          <!-- <div class="column">
              <NumberInput
              v-model="innerValue.pct_escala10"
              :controls="false"
              rules="required"
              name="sala-pct-e10"
              label="% Escala10"
            />
          </div>
          <div class="column">
              <NumberInput
              v-model="innerValue.pct_influencer"
              :controls="false"
              rules="required"
              name="sala-pct-influencer"
              label="% Influenciador"
            />
          </div> -->
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "AddSala",
  props: {
    indexSala: {
      type: Number,
      required: true,
    },
    sala: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      innerValue: this.sala,
    };
  },
  watch: {
    innerValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("change", newValue);
      }
    },
  },
};
</script>

<style></style>
